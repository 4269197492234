<template lang="html">
    <div class="header-con clearfix">
        <div class="header-top bg-black">
            <div class="wrap1200 clearfix">
                <ul class="info-left clearfix">
                    <li><i class="iconfont icon-dianhua"></i> 0731-85685008 </li>
                    <li>
                        <el-tooltip effect="light">
                            <span><i class="iconfont icon-weixin"></i> 关注微信</span>
                            <div slot="content">
                                <img src="../../assets/images/vx1.png" alt="" width="100">
                            </div>
                        </el-tooltip>
                        </li>
                    <li><a href="/aboutUs" target="_blank" style="color: #fff;">关于我们</a></li>
                </ul>
                <ol class="info-right">
                    <li>
                        <el-tooltip effect="light">
                            <span><i class="iconfont icon-shouji"></i> 下载APP</span>
                            <div slot="content">
                                <img src="../../assets/images/load-teacherApp.png" alt="" width="100">
                                <p class="u-center">下载E英语宝教师版</p>
                                <hr>
                                <img src="../../assets/images/load-studentApp.png" alt="" width="100">
                                <p class="u-center">下载E英语宝学生版</p>
                            </div>
                        </el-tooltip>
                    </li>
                    <li>
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                更多服务<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="reset">
                            <el-dropdown-item><a href="http://www.aizhi100.com/#/service/solution/item1" target="_blank">解决方案</a></el-dropdown-item>
                            <el-dropdown-item><a href="http://www.aizhi100.com/#/service/product" target="_blank">产品中心</a></el-dropdown-item>
                            <el-dropdown-item><a href="http://www.aizhi100.com/#/service/resource" target="_blank">资源中心</a></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> 
                    </li>
                    <!-- <li>帮助教程</li> -->
                    <li v-if="name">
                        <el-dropdown @command="UserHandleCommand">
                            <span class="el-dropdown-link">
                                {{name}} <span class="tips-level"> LV {{currentVip}}</span><i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown"  class="reset">
                                <el-dropdown-item command="q">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> 
                    </li>
                    <li v-else @click="linkTologin">登录</li>
                </ol>
            </div>
        </div>
        <div class="header-nav">
            <div class="wrap1200 clearfix">
                <img src="../../assets/images/tiantianlogo.jpg" alt="logo" class="logo-left" @click="changeIndex(1)">
                <ul class="nav-right clearfix">
                    <li :class="{'active': index==1}" @click="changeIndex(1)">首页</li>
                    <li :class="{'active': index==2}" @click="changeIndex(2)">E英语宝</li>
                    <li :class="{'active': index==3}" @click="changeIndex(3)">课堂教学</li>
                    <li :class="{'active': index==4}" @click="changeIndex(4)">教师教研</li>
                    <li :class="{'active': index==5}" @click="changeIndex(5)">考核评测</li>
                    <li :class="{'active': index==6}" @click="openpage()">课后三点半</li>
                    <li :class="{'active': index==7}" @click="changeIndex(7)">动态</li>
                </ul>
                <a href="https://ke.tt100.vip" target="_blank" ref="mylink" v-show="false"></a>
            </div>
        </div>
    </div>
</template>



<script>
    import {quitLogin, getNowCookie, setLocalStorage, getVipLevel, getLocalStorage} from '@/utils/commonBase';
    import {mapGetters} from "vuex";
    export default {
        data() {
            return {
                index: 1,
                currentVip: 0
            }
        },
        computed: { // vuex 储存
            ...mapGetters([
                'name'
            ])
        },
        created() {
            this.index = this.$route.meta.tabIndex;
            this.currentVip = getVipLevel(getLocalStorage('memberCode')); // vip等级
        },
        watch: {
            '$route'(val) {
                this.index = this.$route.meta.tabIndex;
            }
        },
        methods: {
            changeIndex(index) {
                console.log(this.$route);
                this.index = this.$route.meta.tabIndex;
                if(index == this.index && this.$route.meta.level !== 3) return;
                let obj = {
                    1: 'homepage',
                    2: 'eyyb',
                    3: 'classroomTeaching', // 课堂授课
                    4: 'teacherStudy', // 教师教研
                    5: 'testerTeacher', // 教师测评
                    6: 'timeFrees', // 课后三点半
                    7: 'trends', // 动态
                    8: 'teachingResearch', // 教案文萃
                    9: 'expertGuidance', // 专家引领
                }
                this.$router.push({name: obj[index]})
            },
            openpage(){
                 this.$refs['mylink'].click();
            },
            UserHandleCommand(command) {
                if (command === 'q') {
                    // 点击退出
                    quitLogin();
                    // 显示的登录名  vuex登录名
                    setLocalStorage('name', '');
                    this.$store.commit('getName', {name: ''});
                    // this.$router.replace({
                    //     name: 'login'
                    // });
                }
            },
            linkTologin() {
                this.$router.push({
                    name: 'login'
                })
            },
        }
    };
</script>
<style scoped lang="scss">
    @import '@/assets/css/pages/header';
</style>
  