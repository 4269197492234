

/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 老项目域名地址
 * khglUrl: 客户管理域名地址
 * dicUrl : 字典服务器地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 * welUrl :默认欢迎页
 * ccLiveUserId: cc直播用户ID
 *
 */
let baseossUrl = '';
let baseUrl = '';
let ossUrlD = '';
let h5UrlD = '';
let ccLiveUserId = '';
let localKeySet = {};
let iconfontVersion = ['567566_5iv1yh8epy'];
let iconfontUrl = `//at.alicdn.com/t/font_$key.css`;
let codeUrl = `${baseUrl}/code`;
let imgBaseUrl = `https://oss.eyyb.vip/`;
// const env = process.env
var env = 'production'
let aesKey = {};
let resKey = "";//rsa 公钥
 if (env == 'production') {
    console.log('production');
    baseossUrl = 'https://oss.eyyb.vip/';
    localKeySet = {
        bucket: 'zhongtai-prod',
        key: 'LTAI5AzA57kY4kQn',
        region: 'shanghai',
        secret: 'OsnlLD9UZRaRCOrJipPz6G034aJdvk'
    }
    ossUrlD = 'https://oss.eyyb.vip/'; // oss下载前缀
    h5UrlD = `https://m.eyyb.vip/`;//h5地址
    aesKey = { // 生产
        key: 'eyyb201811438611',
        iv: 'iveyyb2018456209'
    }
    baseUrl = `https://apipc.eyyb.vip/`; // 正式打包
    ccLiveUserId = "0EF7DDD60BB707A7";
    resKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvRo5cCb5ScxGQsYuf/63UQFrtMgbfzN42RIEmIxZArwIsM0/O1qjE+YCo/bdThlF9KkY8lzXetwssheRjpVdZVlAsdOQ2RyX7ip+H/0R0ddMJUWdDKeBA+B3yLE1X3QZ+xQ5SdjXzepzBYswP1b5cCvOSo4IoNdSGHDig5ck2YwIDAQAB";
}else if (env == 'test') {
    console.log('test');
    baseossUrl = 'https://oss.eyyb.vip/'
    localKeySet = {
        bucket: 'zhongtai-prod',
        key: 'LTAI5AzA57kY4kQn',
        region: 'shanghai',
        secret: 'OsnlLD9UZRaRCOrJipPz6G034aJdvk'
    }
    baseUrl = `https://apipc-dev.eyyb.vip:444/`; // 开发环境地址
    h5UrlD = `https://m-test.eyyb.vip:444/`;//h5地址
    ccLiveUserId = "EBA788CBF287DE56";
    resKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAp8YJFC0kgwvYJ7Uqp0dh+e8iplKYGC2bT+lPw0Pcku0uI/vyp3casbOB8y8ZtPkcB9pP2TE/EGBW/tSbIEBqEeTPsm/+Yofn5PUkAqFAn9uRSM+4Npp4z7PiaX6fgSshqWHK0f9IXbbM97k/wmoGQgNQQGt7WpqGRYe+teXInQIDAQAB";
}

export {
    baseossUrl, // oss路径前缀
    localKeySet,
    baseUrl,
    iconfontUrl,
    iconfontVersion,
    imgBaseUrl,
    codeUrl,
    ossUrlD,
    env,
    aesKey,
    resKey,
    ccLiveUserId,
    h5UrlD
}
