<template>
  <div class="pages-con">
    <headerView></headerView>
    <div class="m-contanier">
      <!-- <keep-alive> -->
        <router-view/>
      <!-- </keep-alive> -->
    </div>
    <footerView></footerView>
  </div>
</template>

<script>
  import headerView from '@/components/header/header.vue';
  import footerView from '@/components/footer/footer.vue';
  export default {
    components: {
      headerView, footerView
    },
    data() {
      return {
        cc: 12
      }
    },

  }
</script>

<style lang="scss"></style>
