
/**
 * Created by fzy on 2017/11/23.
 */
import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isLoading: false
    },
    mutations: {
        updateLoadingStatus(state, {isLoading}) {
            state.isLoading = isLoading;
        }
    },
    actions: {},
    modules: {
        app
    },
    getters: {
        index: status => status.app.index,
        name: status => status.app.name,
    }
});

export default store;
