/**
 * Created by fzy on 2017/7/8.
 */
import Cookies from 'js-cookie';
import {toObject} from './index';
import JsEncrypt from 'jsencrypt'
import CryptoJS from 'crypto-js'
import { aesKey,resKey } from '@/config/env'
const TokenKey = 'x-access-token'
// let NODE_ENVS = process.env.NODE_ENV;
// console.log('NODE_ENVS', NODE_ENVS)

//解密方法
export function Decrypt(word, keyStr, keyiv) {
    keyStr = keyStr ? keyStr : aesKey.key;
    keyiv = keyiv ? keyiv : aesKey.iv;
    var key  = CryptoJS.enc.Utf8.parse(keyStr);
    var iv  = CryptoJS.enc.Utf8.parse(keyiv);
    var decrypt = CryptoJS.AES.decrypt(word, key, {iv: iv,mode:CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

//加密方法
export function Encrypt(word, keyStr, keyiv) {
    keyStr = keyStr ? keyStr : aesKey.key;
    keyiv = keyiv ? keyiv : aesKey.iv;
    var key  = CryptoJS.enc.Utf8.parse(keyStr);
    var iv  = CryptoJS.enc.Utf8.parse(keyiv);
    let encrypted = CryptoJS.AES.encrypt(word, key, {iv: iv,mode:CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}

export function ResEncrypt(val){
    let encrypt = new JsEncrypt();
    encrypt.setPublicKey(resKey);
    return  encrypt.encrypt(val);
}

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

function setLocalStorage(key, value) {
    window.localStorage.setItem(`${key}_DEV`, value);
}
function getLocalStorage(key) {
    let result = null;
    result = window.localStorage.getItem(`${key}_DEV`);
    return result;
}
function removeLocalStorage(key) {
    window.localStorage.removeItem(`${key}_DEV`);
}
export {setLocalStorage, getLocalStorage, removeLocalStorage};
// 全局取当前环境的cookie
export function getNowCookie() {
    let userInfo = null;
    userInfo = Cookies.get('USER_INFO_DEV');
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
    }
    return {
        userInfo: userInfo
    };
}

// 全局退出登录 -- 删除cookie
export function quitLogin() {
    Cookies.remove('USER_INFO_DEV');
    removeToken();
};

// 全局更新 cookie userInfo
export function resetUserInfoCookie(result = {}) {
    let inWeeks = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    let nowCookie = getNowCookie().userInfo;
    let newCookies = toObject([nowCookie, result]);
    Cookies.set('USER_INFO_DEV', newCookies, {expires: inWeeks});
}

export function getVipLevel(string) {
    if (string==null) return
    console.log('string',string);
    let vipIndex = string.charAt(string.length-1)-0;
    vipIndex = isNaN(vipIndex) ? 0 : parseInt(vipIndex);
    return vipIndex;
}
export function getVipMax(arr) {
    let vipIndex = 0;
    for (let index = 0; index < arr.length; index++) {
        let getArrrNum = arr[index].charAt(arr[index].length-1)-0;
        getArrrNum = isNaN(getArrrNum) ? 0 : parseInt(getArrrNum);
        vipIndex = getArrrNum > vipIndex ? getArrrNum : vipIndex;
    }
    return vipIndex;
}
export function getVipMin(arr) {
    let vipIndex = 0;
    for (let index = 0; index < arr.length; index++) {
        let getArrrNum = arr[index].charAt(arr[index].length-1)-0;
        getArrrNum = isNaN(getArrrNum) ? 0 : parseInt(getArrrNum);
        vipIndex = getArrrNum < vipIndex ? getArrrNum : vipIndex;
    }
    return vipIndex;
}
