import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import filters from './filters';
import {setLocalStorage, getLocalStorage, removeLocalStorage, getToken} from '@/utils/commonBase';
import Cookies from 'js-cookie';
import Mixin from './mixins';
import {otherRouter} from './router/router';
import NProgress from 'nprogress'; // Progress 进度条
import { baseUrl } from '@/config/env';

import {
  Loading,
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  MessageBox,
  Message,
  Notification
} from 'element-ui';

const components = [
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification
];

components.map((component) => {
  if(component.name) {
    Vue.component(component.name, component);
  }
});

Vue.use(Loading.directive);

// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.scss' // 引入基础样式

// 引入富文本编辑器样式
import 'quill/dist/quill.snow.css'

// 实例化Vue的filters
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));
Object.defineProperty(Vue.prototype, '$setLocalStorage', {value: setLocalStorage});
Object.defineProperty(Vue.prototype, '$getLocalStorage', {value: getLocalStorage});
Object.defineProperty(Vue.prototype, '$removeLocalStorage', {value: removeLocalStorage});

// 视频文件
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video;

Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$notify = Notification;
Vue.prototype.$ztMessageBox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
window.$ztMessage = Vue.prototype.$message = Message;
Vue.prototype.$ztStore = window.localStorage;
window.$ztCookies = Vue.prototype.$ztCookies = Vue.$ztCookies = Cookies;

Vue.config.productionTip = false;

window.$axios = Vue.$http = Vue.prototype.$http = axios.create({
  timeout: 50000
});
Vue.prototype.$http.defaults.baseURL = 'https://apiv3.eyyb.vip/'
;

// POST传参序列化
Vue.prototype.$http.interceptors.request.use((config) => {
  NProgress.start();
  config.headers.authorization = getToken() ? getToken() : ''; // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
  return config;
}, (error) => {
  return Promise.reject(error);
});
// code状态码200判断
Vue.prototype.$http.interceptors.response.use((res) => {
  NProgress.done();
  console.log('response', res)
  return res;
}, (error) => {
  console.log('catchs', error)
  let errMsg = error.toString();
  let code = errMsg.substr(errMsg.indexOf('code') + 5);
  Message({
    message: filters['codeTotext'](code),
    type: 'error'
  });
  if (code == '401') { // 没建权
    // router.history.go(-1);
    // store.dispatch("LogOut").then(() => {
    //   router.replace({path: '/login', query: {redirect: router.currentRoute.fullPath}});
    // });
  }
  /* eslint-disable prefer-promise-reject-errors */
  return Promise.reject({status: code});
});

Vue.mixin(Mixin);

// router.beforeEach((to, from, next) => {    
//   // chrome
//   document.body.scrollTop = 0
//   // firefox
//   document.documentElement.scrollTop = 0
//   // safari
//   window.pageYOffset = 0
//   next()
// })



// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
  router,
  store,
  data: {
    currentPageName: ''
  },
  // created () {
  //   let tagsList = [];
  //   otherRouter.children.map((item) => {
  //       // if (item.children.length <= 1) {
  //           tagsList.push(item);
  //       // } else {
  //       //     tagsList.push(...item.children);
  //       // }
  //   });
  //   // this.$store.commit('setTagsList', tagsList);
  // },
  mounted () {
      this.currentPageName = this.$route.name;
      // 显示已打开的菜单
      // this.$store.commit('setOpenedList');
  },
  render: h => h(App)
}).$mount('#app')
