import pagesMain from '@/views/pages/pages.vue';

// 不作为pagesMain组件的子页面展示的页面单独写
export const loginRouter = {
    path: '/login',
    name: 'login',
    title: '登录',
    meta: {},
    component: resolve => require(['@/views/login/login'], resolve)
};

export const page404 = {
    path: '*',
    name: 'p404',
    title: '404-页面不存在',
    meta: {},
    component: resolve => require(['@/views/404/index'], resolve)
};



export const appRouter = [
    {
        path: '/',
        name: 'home',
        component: pagesMain,
        redirect: {
            name: 'homepage'
        },
        children: [
            {
                path: 'homepage',
                name: 'homepage',
                title: '首页',
                meta: {
                    requestAuth: true,
                    tabIndex: 1
                },
                component: resolve => require(['@/views/pages/homePage/homePage'], resolve)
            }
        ]
    },
    {
        path: '/eyyb',
        name: 'eyyb',
        title: 'eyyb',
        meta: {
            requestAuth: true,
            tabIndex: 2
        },
        component: pagesMain,
        redirect: {
            name: 'eyybIndex'
        },
        children: [{
            path: 'eyybIndex',
            name: 'eyybIndex',
            title: 'eyybIndex',
            meta: {
                requestAuth: true,
                tabIndex: 2
            },
            component: resolve => require(['@/views/pages/eyyb/eyyb'], resolve)
        }]
    },
    {
        path: '/teachingResearch',
        name: 'teachingResearch',
        component: pagesMain,
        redirect: {
            name: 'teachingResearchIndex'
        },
        children: [{
            path: 'teachingResearchIndex',
            name: 'teachingResearchIndex',
            title: '教案文萃',
            meta: {
                requestAuth: true,
                tabIndex: 1, // 8
                level: 3,
                moduleId: 'Jp8yfwV8GaXyJXC5BxrarG'
            },
            component: resolve => require(['@/views/pages/teachingResearch/teachingResearch'], resolve)
        }, {
            path: 'teachingResearchDetails/:id',
            name: 'teachingResearchDetails',
            title: '教案文萃',
            meta: {
                requestAuth: true,
                tabIndex: 1, // 8
                level: 3,
                moduleId: 'Jp8yfwV8GaXyJXC5BxrarG'
            },
            component: resolve => require(['@/views/pages/teachingResearch/teachingResearchDetails'], resolve)
            
        }]
    },
    {
        path: '/trends',
        name: 'trends',
        component: pagesMain,
        redirect: {
            name: 'trendsIndex'
        },
        children: [
            {
                path: 'trendsIndex',
                name: 'trendsIndex',
                title: '动态',
                meta: {
                    requestAuth: true,
                    tabIndex: 7,
                    moduleId: 'EKxgrAEN74vQTQf34pMBX8'
                },
                component: resolve => require(['@/views/pages/trends/trends'], resolve)
            },
            {
                path: 'trendsDetails/:id',
                name: 'trendsDetails',
                title: '动态详情',
                meta: {
                    level: 3,
                    requestAuth: true,
                    tabIndex: 7
                },
                component: resolve => require(['@/views/pages/trends/trendsDetails'], resolve)
            }
        ]
    },
    {
        path: '/timeFrees',
        name: 'timeFrees',
        component: pagesMain,
        redirect: {
            name: 'timeFreesIndex'
        },
        children: [{
            path: 'timeFreesIndex',
            name: 'timeFreesIndex',
            title: '课后三点半',
            meta: {
                requestAuth: true,
                tabIndex: 6,
                moduleId: '8Y8kspgKn7UPD8w4FCLcew'
            },
            component: resolve => require(['@/views/pages/timeFrees/timeFrees'], resolve)
        }, {
            path: 'timeFreesDetails/:id',
            name: 'timeFreesDetails',
            title: '课后三点半',
            meta: {
                requestAuth: true,
                tabIndex: 6,
                level: 3,
                moduleId: '8Y8kspgKn7UPD8w4FCLcew'
            },
            component: resolve => require(['@/views/pages/timeFrees/timeFreesDetails'], resolve)
        }]
    },
    {
        path: '/teacherStudy',
        name: 'teacherStudy',
        component: pagesMain,
        redirect: {
            name: 'teacherStudyIndex'
        },
        children: [{
            path: 'teacherStudyIndex',
            name: 'teacherStudyIndex',
            title: '教师教研',
            meta: {
                requestAuth: true,
                tabIndex: 4,
                moduleId: 'XFQF2N6xdwWrN5r98YKtHG'
            },
            component: resolve => require(['@/views/pages/teacherStudy/teacherStudy'], resolve)
        }, {
            path: 'teacherStudyDetails',
            name: 'teacherStudyDetails',
            title: '教师教研详情',
            meta: {
                requestAuth: true,
                level: 3,
                tabIndex: 4,
                moduleId: 'XFQF2N6xdwWrN5r98YKtHG'
            },
            component: resolve => require(['@/views/pages/teacherStudy/teacherStudyDetails'], resolve)
            
        }]
    },
    {
        path: '/expertGuidance',
        name: 'expertGuidance',
        component: pagesMain,
        redirect: {
            name: 'expertGuidanceIndex'
        },
        children: [{
            path: 'expertGuidanceIndex',
            name: 'expertGuidanceIndex',
            title: '专家引领',
            meta: {
                requestAuth: true,
                tabIndex: 1,
                level: 3,
            },
            component: resolve => require(['@/views/pages/expertGuidance/expertGuidance'], resolve)
        },{
            path: 'subExpertGuidance/:id',
            name: 'subExpertGuidance',
            title: '专家引领2',
            meta: {
                requestAuth: true,
                tabIndex: 1,
                level: 3
            },
            component: resolve => require(['@/views/pages/expertGuidance/subExpertGuidance'], resolve)
        },{
            path: 'expertDialog/:id',
            name: 'expertDialog',
            title: '专家引领文章',
            meta: {
                requestAuth: true,
                tabIndex: 1,
                level: 3
            },
            component: resolve => require(['@/views/pages/expertGuidance/expertDialog'], resolve)
        }]
    },
    {
        path: '/testerTeacher',
        name: 'testerTeacher',
        component: pagesMain,
        redirect: {
            name: 'testerTeacherIndex'
        },
        children: [{
            path: 'testerTeacherIndex',
            name: 'testerTeacherIndex',
            title: '教师测评',
            meta: {
                requestAuth: true,
                tabIndex: 5 
            },
            component: resolve => require(['@/views/pages/testerTeacher/testerTeacher'], resolve)
        }]
    }, {
        path: '/classroomTeaching',
        name: 'classroomTeaching',
        component: pagesMain,
        redirect: {
            name: 'classroomTeachingIndex'
        },
        children: [{
            path: 'classroomTeachingIndex',
            name: 'classroomTeachingIndex',
            title: '课堂授课',
            meta: {
                requestAuth: true,
                tabIndex: 3
            },
            component: resolve => require(['@/views/pages/classroomTeaching/classroomTeaching'], resolve)
        }]
    }, {
        path: '/aboutUs',
        name: 'aboutUs',
        component: pagesMain,
        redirect: {
            name: 'aboutUsIndex'
        },
        children: [{
            path: 'aboutUsIndex',
            name: 'aboutUsIndex',
            title: '关于我们',
            meta: {
                requestAuth: true,
                tabIndex: 1,
                level: 3
            },
            component: resolve => require(['@/views/pages/aboutUs/aboutUs'], resolve)
        }]
    }
]

export const routers = [
    loginRouter,
    page404,
    ...appRouter
];