/**
 * 毫秒转换成日期
 *
 */
function millisecondFormat(time, flag) {
    if (typeof time === 'number') {
        var oDate = new Date(time);
        var year = oDate.getFullYear();
        var month = (oDate.getMonth() + 1) >= 10 ? oDate.getMonth() + 1 : '0' + (oDate.getMonth() + 1);
        var date = oDate.getDate() >= 10 ? oDate.getDate() : '0' + oDate.getDate();
        var hours = oDate.getHours() >= 10 ? oDate.getHours() : '0' + oDate.getHours();
        var minutes = oDate.getMinutes() >= 10 ? oDate.getMinutes() : '0' + oDate.getMinutes();
        var seconds = oDate.getSeconds() >= 10 ? oDate.getSeconds() : '0' + oDate.getSeconds();
        if (flag === 'ms') {
            return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
        } else {
            return `${year}-${month}-${date}`;
        }
    } else {
        return '无';
    }
};
function toIntNumber(val) {
    return parseInt(val);
};
function numberToFixed2(val) {
    return parseFloat(val).toFixed(2);
};

function priceToText(val, type) {
    // 钱保留2位数字
    if (!val) {
        return 0;
    } else {
        return type ? parseInt(val) : parseFloat(val).toFixed(2);
    }
};
function priceToText2(val, none) {
    // 钱保留2位数字
    if (!val) {
        return '';
    } else {
        return none ? parseInt(val) : parseFloat(val).toFixed(2);
    }
};
function numberToText(val) {
    // 空转0
    if (!val) {
        return 0;
    } else {
        return val;
    }
};
// 转几天几时
function MSFormat(time) {
    let xs = (1000 * 60 * 60);
    let fz = (1000 * 60);
    let t = (1000 * 60 * 60 * 24);
    if (time <= fz) {
        return Math.ceil(time / 1000) + '秒';
    } else if (time <= xs) {
        return Math.floor((time / fz)) + '分钟' + Math.floor((time % fz) / 1000) + '秒';
    } else if (1000 * 60 * 60 * 1 < time && time <= 1000 * 60 * 60 * 24) {
        return Math.floor(time / xs) + '小时' + Math.floor((time % xs) / fz) + '分钟' + Math.floor(((time % xs) % fz) / 1000) + '秒';
    } else if (1000 * 60 * 60 * 24 < time && time <= 1000 * 60 * 60 * 24 * 15) {
        return Math.floor(time / t) + '天' + Math.floor(time % t / xs) + '小时' + Math.floor(((time % t) % xs) / fz) + '分' + Math.floor((((time % t) % xs) % fz) / 1000) + '秒';
    }
};
function codeTotext(code) {
    code = code ? code : 'default';
    let obj = {
        '478': '验证码错误,请重新输入',
        '479': '演示环境，没有权限操作',
        '401': '网站未登录，请登录后操作。',
        '402': '权限不足，请联系管理员',
        '403': '当前操作没有权限',
        '400': '用户名不存在或密码错误',
        '404': '请求错误,未找到该资源',
        '405': '请求方法未允许',
        '408': '请求超时',
        '500': '服务器端出错',
        '501': '网络未实现',
        '502': '网络错误',
        '503': '服务不可用',
        '504': '网络超时',
        '505': 'http版本不支持该请求',
        'default': '系统未知错误,请反馈给管理员'
    }
    return obj[code];
}
function indexTolink(code) {
    code = code ? code : 'default';
    let obj = {
        1: 'homepage',
        2: 'eyyb',
        3: 'classroomTeaching', // 课堂授课
        4: 'teacherStudy', // 教师教研
        5: 'testerTeacher', // 教师测评
        6: 'timeFrees', // 课后三点半
        7: 'trends', // 动态
        8: 'teachingResearch', // 教案文萃
        9: 'expertGuidance', // 专家引领
    }
    return obj[code];
}

export default {
    MSFormat,
    millisecondFormat,
    toIntNumber,
    numberToFixed2,
    priceToText,
    indexTolink,
    priceToText2,
    numberToText,
    codeTotext
};
