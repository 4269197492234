var mixin = {
  data: () => {
      return {
          // input 前面label的宽度
          // xeLabelWidth: '90px',
          // xeLabelWidth80: '80px',
          // tableLoading: false,
          // searchFormbut: false // 全局高级搜素
      };
  },
  created() {
      // this.$xeBus.$on('on-advancedSearch-hide', flag => {
      //     this.searchFormbut = flag;
      // });
  },
  methods: {
      // goBackFn() {
      //     window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
      // }
  },
  watch: {
      // searchFormbut(n) {
      //     if (n === true) {
      //         this.$nextTick(() => {
      //             $('.advancedSearch').show().css({top: $('.u-top-search').height()}).animate({right: 0, opacity: 1}, 100);
      //         });
      //     } else {
      //         this.$nextTick(() => {
      //             $('.advancedSearch').animate({right: '-10%', opacity: 0}, 100, () => {
      //                 $('.advancedSearch').hide();
      //             });
      //         });
      //     }
      // }
  }
};

export default mixin;
