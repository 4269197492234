/**
 * Created by fzy on 2017/11/23.
 */
import { setLocalStorage, getLocalStorage } from '@/utils/commonBase'
const app = {
    state: {
        index: getLocalStorage('index') ? getLocalStorage('index') : 0,
        name: getLocalStorage('name') ? getLocalStorage('name') : ''
    },
    mutations: {
        getIndex(state, {index}) {
            console.log('index', index)
            state.index = index;
        },
        getName(state, {name}) {
            console.log('name', name)
            state.name = name;
        },
    },

};
export default app;
